import {createStore} from 'vuex'
import TokenService from "@services/tokenService";
import route from '@router';
import jwt_decode from "jwt-decode";

export default createStore({
    state: {
        currentUser: getSavedState('auth.currentUser'),
        userInformation: getSavedState('userInformation'),
        userParams: getSavedState('userParams'),
    },
    mutations: {
        SET_CURRENT_USER(state, newValue) {
            state.currentUser = newValue
            saveState('auth.currentUser', newValue)
        },
        SET_USER_INFO(state, newValue) {
            if (newValue && newValue.jwt) {
                const decoded = jwt_decode(newValue.jwt);
                const value = {
                    platform: decoded.platform,
                    userId: decoded.userId,
                    roleId: decoded.roleId,
                    firstName: decoded.firstName,
                    lastName: decoded.lastName,
                    email: decoded.email,
                    tel: decoded.tel,
                    pwdReset: decoded.pwdReset,
                    agerId: decoded.agerId,
                    uerId: decoded.uerId,
                    ceiId: decoded.ceiId,
                    agerRole: decoded.agerRole,
                    ceiRole: decoded.ceiRole,
                    userGroupName: decoded.userGroupName,
                    userGroupTechName: decoded.userGroupTechName,
                };
                state.userInformation = value
                saveState('userInformation', value)
            }
        },
        SET_USER_PARAMS(state, newValue) {
            state.userParams = newValue
            saveState('userParams', newValue)
        },
    },
    getters: {
        // Whether the user is currently logged in.
        loggedIn(state) {
            return !!state.currentUser
        },
        username(state) {
            return state.userInformation.firstName + " " + state.userInformation.lastName
        },
        userId(state) {
            return state.userInformation.userId
        }
    },
    actions: {
        // This is automatically run in `src/state/store.js` when the app
        // starts, along with any other actions named `init` in other modules.
        init({state, dispatch}) {
            dispatch('validate')
        },

        // Logs in the current user.
        logIn({commit, dispatch, getters}, {username, password} = {}) {
            if (getters.loggedIn) return dispatch('validate');
            let user = {login: username, password: password};
            return TokenService.login(user).then(data => {
                const user = data;
                commit('SET_CURRENT_USER', user)
                commit('SET_USER_INFO', user)
                commit('SET_USER_PARAMS', {
                    link: {
                        searchValue: "",
                        typeToShow: "all",
                        perDay: false,
                        date: new Date(),
                        author: "",
                        night: null,
                        sheetType: null,
                        agerIdSelected: null,
                        uerIdSelected: null,
                        ceiIdSelected: null,
                        isShowingAll: false,
                        startDate: null,
                        endDate: null,
                        noFiche: "",
                    },
                    forecast: {
                        searchValue: "",
                        typeToShow: "all",
                        perDay: false,
                        date: new Date(),
                        author: "",
                        agerIdSelected: null,
                        uerIdSelected: null,
                        ceiIdSelected: null,
                        startDate: null,
                        endDate: null,
                        noFiche: "",
                    },
                    intervention: {
                        searchValue: "",
                        presenceRi: null,
                        ddp: null,
                        callOrigin: null,
                        axeInterventionId: null,
                        sens: null,
                        typeIntervention: null,
                        author: "",
                        reference: null,
                        startDate: null,
                        endDate: null,
                        noInterCei: "",
                    },
                })
                return user
            });
        },
        setUserParams({commit}, userParams) {
            commit('SET_USER_PARAMS', userParams)
        },
        resetLinkUserParams({commit}) {
            let data = getSavedState('userParams');
            data.link = {
                searchValue: "",
                typeToShow: "all",
                perDay: false,
                date: new Date(),
                author: "",
                night: null,
                sheetType: null,
                agerIdSelected: null,
                uerIdSelected: null,
                ceiIdSelected: null,
                isShowingAll: false,
                startDate: null,
                endDate: null,
                noFiche: "",
            };
            commit('SET_USER_PARAMS', data)
        },
        resetForecastUserParams({commit}) {
            let data = getSavedState('userParams');
            data.forecast = {
                searchValue: "",
                typeToShow: "all",
                perDay: false,
                date: new Date(),
                author: "",
                agerIdSelected: null,
                uerIdSelected: null,
                ceiIdSelected: null,
                startDate: null,
                endDate: null,
                noFiche: "",
            };
            commit('SET_USER_PARAMS', data)
        },
        resetInterventionUserParams({commit}) {
            let data = getSavedState('userParams');
            data.intervention = {
                searchValue: "",
                presenceRi: null,
                ddp: null,
                callOrigin: null,
                axeInterventionId: null,
                sens: null,
                typeIntervention: null,
                author: "",
                reference: null,
                startDate: null,
                endDate: null,
                noInterCei: "",
            };
            commit('SET_USER_PARAMS', data)
        },

        // Logs out the current user.
        logOut({commit}) {
            commit('SET_CURRENT_USER', null);
            commit('SET_USER_PARAMS', null);
            route.push({name: "login"});
        },

        logOutExpired({commit}) {
            commit('SET_CURRENT_USER', null);
            commit('SET_USER_PARAMS', null);
            route.push({name: "login", query: {redirectFrom: route.currentRoute.value.fullPath}});
        },

        // Validates the current user's token and refreshes it
        // with new data from the API.
        validate({commit, state}) {
            if (!state.currentUser) return Promise.resolve(null)
            return state.currentUser;
        },
    },
    modules: {}
})

// ===
// Private helpers
// ===

function getSavedState(key) {
    return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}